<template>
  <main class="space-y-16 tablet-up:space-y-32">
    <PageHeader>
      <template v-slot:titles>
        <h1 v-if="queryList">{{ queryList.details.name }}</h1>
        <h1 v-else>Votes</h1>
      </template>
    </PageHeader>

    <p v-if="error">{{ error }}</p>

    <p v-if="!error && !loading && !queryList">Nothing to show</p>

    <section v-if="loading" class="space-y-16">
      <p>Loading ...</p>

      <ul class="space-y-8">
        <li v-for="(n, index) in 5" :key="index" class="block h-84 overflow-hidden rounded-16">
          <SkeletonLoader />
        </li>
      </ul>
    </section>

    <section v-if="queryList">
      <p v-if="!queryList.results.length">No results</p>

      <Pagination
        v-if="queryList.results.length"
        v-bind="queryList.metadata"
        :results="queryList.results.length"
        type="votes"
        @paginate="onNextPage"
      >
        <MovieList :list="queryList.results" />
      </Pagination>
    </section>
  </main>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import MovieList from "@/components/MovieList";
import PageHeader from "@/components/PageHeader";
import Pagination from "@/components/Pagination";
import Relations from "@/globals";
import SkeletonLoader from "@/components/SkeletonLoader";

export default {
  name: "VotesLists",
  components: {
    MovieList,
    PageHeader,
    Pagination,
    SkeletonLoader
  },
  data() {
    return {
      error: false,
      loading: false
    };
  },
  metaInfo() {
    return {
      title: "Jille Borg",
      titleTemplate: `%s | ${this.queryList ? this.queryList.details.name : "Movie Votes"}`,
      meta: [
        { property: "og:type", content: "article" },
        {
          property: "og:title",
          content: `Jille Borg | ${this.queryList ? this.queryList.details.name : "Movie Votes"}`
        },
        { property: "og:description", content: this.queryList ? `All votes for ${this.queryList.details.name}` : "" }
      ]
    };
  },
  computed: {
    ...mapGetters(Relations.Votes, ["queryList"]),
    ...mapState(Relations.Votes, ["queries"]),
    queryList() {
      return this.queries[this.queryString];
    },
    queryString() {
      const { field, value } = this.$route.params;
      return `${field}/${value}`;
    }
  },
  async mounted() {
    this.error = false;

    if (!this.queryList) {
      this.loading = true;
      const { field, value } = this.$route.params;
      const response = await this.getVotes({ field, page: 1, value });

      if (!response || !response.results) {
        this.error = response;
      }
    }

    this.loading = false;
  },
  methods: {
    ...mapActions(Relations.Votes, ["getVotes"]),
    async onNextPage(page) {
      const { field, value } = this.$route.params;
      return await this.getVotes({ field, page, value });
    }
  }
};
</script>
