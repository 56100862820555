export default {
  activeQueryString: "",
  hasRequestedStats: false,
  queries: {},
  recentVoteList: [],
  singleVotesList: [],
  stats: {
    topVotedActors: [],
    topVotedCountries: [],
    topVotedDirectors: [],
    topVotedGenres: [],
    topVotedYears: [],
    totalVotes: "",
    votesCount: []
  }
};
