var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"space-y-12"},[_c('header',{staticClass:"flex justify-center"},[_c('Header',[_vm._v("Recent votes")])],1),_c('section',[(_vm.loading)?_c('ul',{staticClass:"space-y-8"},_vm._l((10),function(n,index){return _c('li',{key:index,staticClass:"block h-84 overflow-hidden rounded-16"},[_c('SkeletonLoader')],1)}),0):_vm._e(),(!_vm.loading && _vm.recentVoteList.length)?_c('ul',{staticClass:"space-y-8"},_vm._l((_vm.recentVoteList),function(ref,index){
var date = ref.date;
var name = ref.name;
var poster = ref.poster;
var seqId = ref.seqId;
var vote = ref.vote;
var years = ref.years;
return _c('li',{key:index,staticClass:"bg-grey hover:bg-grey-dark rounded-16"},[_c('router-link',{staticClass:"fade-in flex items-center justify-between px-16 py-12 space-x-16 transition",attrs:{"to":{ name: 'single', params: { seqId: seqId } }}},[_c('div',{staticClass:"block h-60 w-40"},[_c('img',{staticClass:"h-60 object-cover rounded-6 w-40",attrs:{"src":poster,"title":name}})]),_c('section',{staticClass:"flex-1 truncate space-y-4"},[_c('p',{staticClass:"font-medium truncate"},[_vm._v(_vm._s(name)+" ("+_vm._s(years)+")")]),_c('p',{staticClass:"font-light text-12"},[_vm._v("Voted "+_vm._s(_vm.dateFormat(date)))])]),_c('aside',[_c('Vote',{attrs:{"show-bar":false,"value":vote}})],1),_c('ChevronRight')],1)],1)}),0):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }