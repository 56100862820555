<template>
  <nav class="bg-blue-dark flex flex-col mb-16 tablet-up:mb-32">
    <div class="bg-blue flex items-center justify-center p-16 tablet-up:p-20">
      <router-link :to="{ name: 'home' }">
        <h1 class="font-normal leading-none space-x-12 text-20 text-white uppercase">
          <span class="font-bold">Movie</span>
          <img class="inline-block" src="/images/owl.png" height="50" width="50" />
          <span>Votes</span>
        </h1>
      </router-link>
    </div>

    <ul class="flex items-center mx-auto space-x-20">
      <li v-for="({ name, title }, key) in navigation" :key="key">
        <router-link
          class="
            link
            block
            text-12 text-white
            border-b-4 border-blue-dark
            pb-8
            tablet-up:pb-12
            pt-12
            tablet-up:pt-16
            px-16
            tracking-normal
            transition
            uppercase
          "
          :to="{ name }"
        >
          {{ title }}
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapState } from "vuex";

import Relations from "@/globals";

export default {
  name: "Navigation",
  data() {
    return {
      navigation: [
        { name: "home", title: "Home" },
        { name: "filters", title: "Zoeken" }
      ]
    };
  },
  computed: {
    ...mapState(Relations.Users, ["currentUser", "isLoggedIn", "stats"])
  },
  mounted() {
    if (this.isLoggedIn) {
      return this.navigation.push({ name: "account", title: "Account" });
    }
  }
};
</script>

<style lang="scss" scoped>
.link {
  &:hover:not(.is-active) {
    border-color: rgba(255, 255, 255, 0.3);
  }
  &.is-active {
    border-color: white;
  }
}
</style>
