import Vue from "vue";
import Router from "vue-router";

import Filters from "@/routes/Filters";
import Home from "@/routes/Home";
import MovieEdit from "@/routes/movies/MovieEdit";
import MovieMain from "@/routes/movies/MovieMain";
import MovieSingle from "@/routes/movies/MovieSingle";
import RelationsLists from "@/routes/RelationsLists";
import UserAccount from "@/routes/users/UserAccount";
import UserLogin from "@/routes/users/UserLogin";
// import UserRegister from "@/routes/users/UserRegister";
import VotesLists from "@/routes/VotesLists";

import users from "./store/users";

Vue.use(Router);

const router = new Router({
  mode: "history",
  linkExactActiveClass: "is-active",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "",
      name: "home",
      component: Home
    },
    {
      path: "/filters",
      name: "filters",
      component: Filters
    },
    {
      path: "/votes/:field/:value",
      name: "votes",
      component: VotesLists
    },
    {
      path: "/relations/:relation/:sort?",
      name: "relations",
      component: RelationsLists
    },
    {
      path: "/login",
      name: "login",
      component: UserLogin,
      meta: { requiresLogin: false }
    },
    /* {
      path: "/register",
      name: "register",
      component: UserRegister,
      meta: { requiresLogin: false }
    }, */
    {
      path: "/movie",
      name: "movie",
      component: MovieMain,
      children: [
        {
          path: "single/:seqId",
          name: "single",
          component: MovieSingle
        },
        {
          path: "single/:seqId/edit",
          name: "edit",
          component: MovieEdit,
          meta: { requiresLogin: true }
        }
      ]
    },
    {
      path: "/user/account",
      name: "account",
      component: UserAccount,
      meta: { requiresLogin: true }
    }
  ]
});

router.beforeEach((to, _, next) => {
  if (to.meta.requiresLogin !== undefined) {
    return to.meta.requiresLogin === users.state.isLoggedIn ? next() : next("/");
  }
  next();
});

export default router;
