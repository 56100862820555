import Vue from "vue";
import Vuex from "vuex";

import movies from "./movies";
import relations from "./relations";
import users from "./users";
import votes from "./votes";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    movies,
    relations,
    users,
    votes
  }
});
