<template>
  <ul class="space-y-8">
    <li v-for="field in list" :key="field.seqId" class="bg-grey hover:bg-grey-dark rounded-16 transition">
      <router-link
        :to="{ name: 'votes', params: { field: relation, value: field.seqId } }"
        class="fade-in flex items-center justify-between min-h-64 px-16 py-12 hover:shadow-sm space-x-16 transition"
      >
        <img class="h-32 rounded-full" :src="`/images/${relationImage(relation)}`" :title="field.name" />

        <section class="flex-1">
          <p>{{ field.name }}</p>
        </section>

        <aside class="flex space-x-16">
          <Badge :value="field.count || 0" />
          <Vote v-if="field.avgVote" :show-bar="false" :value="field.avgVote" />
        </aside>

        <ChevronRight />
      </router-link>
    </li>
  </ul>
</template>

<script>
import { getImage } from "@/services/vote.service";
import Badge from "@/components/Badge";
import ChevronRight from "@/components/icons/ChevronRight";
import Vote from "@/components/Vote";

export default {
  name: "RelationList",
  components: {
    Badge,
    ChevronRight,
    Vote
  },
  props: {
    list: {
      required: true,
      type: Array
    },
    relation: {
      required: true,
      type: String
    }
  },
  methods: {
    relationImage(field) {
      return getImage(field);
    }
  }
};
</script>
