import Api from "@/services/api.service";

export default {
  async addRelationToMovie({ commit }, { movieSeqId, relation, seqId }) {
    const headers = { Authorization: `Bearer ${localStorage.MMD}` };
    const response = await Api.request({
      method: "PUT",
      url: `user/movie/${movieSeqId}/${relation}/${seqId}`,
      headers
    });

    if (response.data) {
      commit("updateMovieDetailList", response.data);
    }

    return response.data || false;
  },
  async addMovie(_, imdbId) {
    const headers = { Authorization: `Bearer ${localStorage.MMD}` };
    const response = await Api.request({ method: "POST", url: "user/movie/add", data: { imdbId }, headers });
    return response.error || response.data;
  },
  async getMovie({ commit }, seqId) {
    const response = await Api.request({ method: "GET", url: `movies/single/${seqId}` });
    if (response.data) {
      commit("setMovieDetailsList", response.data);
    }
    return response.error || response.data;
  },
  async findMoviesByKeyword(_, keyword) {
    const response = await Api.request({ method: "GET", url: `movies/find/${keyword}` });
    return response.error || response.data;
  },
  async removeRelationFromMovie({ commit }, { movieSeqId, relation, seqId }) {
    const headers = { Authorization: `Bearer ${localStorage.MMD}` };
    const response = await Api.request({
      method: "DELETE",
      url: `user/movie/${movieSeqId}/${relation}/${seqId}`,
      headers
    });

    if (response.data) {
      commit("updateMovieDetailList", response.data);
    }
    return response.data || false;
  },
  async updateMovieByProperty(_, { seqId, key, value }) {
    const headers = { Authorization: `Bearer ${localStorage.MMD}` };
    const response = await Api.request({ method: "PUT", url: `user/movie/${seqId}`, data: { key, value }, headers });
    return response.error || response.data;
  }
};
