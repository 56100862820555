<template>
  <main class="space-y-16 tablet-up:space-y-32">
    <PageHeader v-if="relation">
      <template v-slot:titles>
        <h1 class="capitalize">{{ relation }}</h1>
      </template>
    </PageHeader>

    <p v-if="error">{{ error }}</p>

    <p v-if="!error && !loading && !queryList">Nothing to show</p>

    <section v-if="loading" class="space-y-16">
      <p>Loading ...</p>

      <ul class="space-y-8">
        <li v-for="(n, index) in 10" :key="index" class="block h-64 overflow-hidden rounded-16">
          <SkeletonLoader />
        </li>
      </ul>
    </section>

    <section v-if="queryList">
      <p v-if="!queryList.results.length">No results</p>

      <Pagination
        v-if="queryList.results.length"
        v-bind="queryList.metadata"
        :results="queryList.results.length"
        :type="relation"
        @paginate="onNextPage"
      >
        <template v-slot:sort>
          <div class="flex space-x-8">
            <p class="hidden tablet-up:block">Sort by</p>

            <router-link
              :to="{ name: 'relations', params: { relation, sort: 'count' } }"
              :class="['font-bold', { active: sort === 'count' }]"
            >
              count
            </router-link>

            <router-link
              :to="{ name: 'relations', params: { relation, sort: 'name' } }"
              :class="['font-bold', { active: sort === 'name' }]"
            >
              {{ title }}
            </router-link>

            <router-link
              :to="{ name: 'relations', params: { relation, sort: 'vote' } }"
              :class="['font-bold', { active: sort === 'vote' }]"
            >
              vote
            </router-link>
          </div>
        </template>

        <RelationList :list="queryList.results" :relation="relation" />
      </Pagination>
    </section>
  </main>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PageHeader from "@/components/PageHeader";
import Pagination from "@/components/Pagination";
import RelationList from "@/components/RelationList";
import Relations from "@/globals";
import SkeletonLoader from "@/components/SkeletonLoader";

export default {
  name: "RelationsLists",
  components: {
    PageHeader,
    Pagination,
    RelationList,
    SkeletonLoader
  },
  data() {
    return {
      error: false,
      loading: false
    };
  },
  metaInfo() {
    return {
      title: "Jille Borg",
      titleTemplate: `%s | ${this.toUppercase(this.relation)}`,
      meta: [
        { property: "og:type", content: "article" },
        { property: "og:title", content: `Jille Borg | ${this.toUppercase(this.relation)}` },
        {
          property: "og:description",
          content: this.relation ? `All votes for ${this.relation}` : "Movie Votes"
        }
      ]
    };
  },
  watch: {
    $route() {
      this.doQuery();
    }
  },
  computed: {
    ...mapState("relations", ["queries"]),
    queryList() {
      return this.queries[this.queryString];
    },
    queryString() {
      const { relation, sort } = this.$route.params;
      return `${relation}/sort/${sort || "count"}`;
    },
    relation() {
      return this.$route.params.relation;
    },
    sort() {
      return this.$route.params.sort || "count";
    },
    title() {
      return this.relation === Relations.Countries ? "country" : this.relation.slice(0, -1);
    }
  },
  async mounted() {
    this.doQuery();
  },
  methods: {
    ...mapActions("relations", ["getRelations"]),
    async doQuery() {
      this.error = false;

      if (!this.queryList) {
        this.loading = true;

        const { relation, sort } = this.$route.params;
        const response = await this.getRelations({ page: 1, relation, sort });

        if (!response || !response.results) {
          this.error = response;
        }
      }

      this.loading = false;
    },
    async onNextPage(page) {
      const { relation, sort } = this.$route.params;
      return await this.getRelations({ page, relation, sort });
    },
    toUppercase(s) {
      if (typeof s !== "string") {
        return "Movie Votes";
      }
      return s.charAt(0).toUpperCase() + s.slice(1);
    }
  }
};
</script>

<style lang="scss" scoped>
.active {
  text-decoration: underline;
}
</style>
