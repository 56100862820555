import Api from "@/services/api.service";

export default {
  async changePassword(_, password) {
    const headers = { Authorization: `Bearer ${localStorage.MMD}` };
    const response = await Api.request({ method: "POST", url: "user/change-password", data: { password }, headers });
    return response.error || response.data;
  },
  async getUser({ commit }) {
    const headers = { Authorization: `Bearer ${localStorage.MMD}` };
    const response = await Api.request({ method: "GET", url: "user/profile", headers });
    if (response.data) {
      commit("setCurrentUser", response.data);
    }
    return response.error || response.data;
  },
  async loginUser({ commit }, data) {
    const response = await Api.request({ method: "POST", url: "login", data });
    if (response.data && response.data.token && response.data.user) {
      localStorage.MMD = response.data.token;
      commit("setCurrentUser", response.data.user);
      commit("setLoggedInState");
    }
    return response.error || response.data;
  },
  logout({ commit }) {
    localStorage.removeItem("MMD");
    commit("setCurrentUser", null);
    commit("setLoggedInState");
  },
  async registerUser(_, data) {
    const response = await Api.request({ method: "POST", url: "register", data });
    return response.error || response.data;
  },
  async runMigration() {
    const headers = { Authorization: `Bearer ${localStorage.MMD}` };
    const response = await Api.request({ method: "POST", url: "user/migration", headers });
    return response.error || response.data;
  },
  async updateStats() {
    const headers = { Authorization: `Bearer ${localStorage.MMD}` };
    const response = await Api.request({ method: "POST", url: "user/update-stats", headers });
    return response.error || response.data;
  }
};
