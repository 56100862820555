import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "tailwindcss/tailwind.css"
import "./main.scss";

import VCalendar from "v-calendar";
import VueMeta from "vue-meta";

Vue.config.productionTip = false;

Vue.use(VCalendar);
Vue.use(VueMeta);

new Vue({ store, router, render: h => h(App) }).$mount("#app");
