<template>
  <div class="flex flex-col min-h-screen" id="app">
    <Navigation />

    <div class="flex-1 max-w-640 tablet-up:min-w-640 mx-auto px-8 tablet-up:px-16 w-full">
      <RouterView />
    </div>

    <Footer />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

import Footer from "@/components/Footer";
import Navigation from "@/components/Navigation";
import Relations from "@/globals";

export default {
  name: "App",
  components: {
    Footer,
    Navigation
  },
  data() {
    return {
      render: false
    };
  },
  async mounted() {
    this.setLoggedInState();

    if (this.isLoggedIn) {
      await this.getUser();
    }
  },
  computed: {
    ...mapState(Relations.Users, ["isLoggedIn"])
  },
  methods: {
    ...mapActions(Relations.Users, ["getUser"]),
    ...mapMutations(Relations.Users, ["setLoggedInState"])
  }
};
</script>

<!-- 
https://tailwindcss.com/docs/optimizing-for-production#writing-purgeable-html

Tailwind compile:

laptop-up:overflow-y-scroll
-->
