<template>
  <main class="space-y-16 tablet-up:space-y-32">
    <PageHeader v-if="currentUser">
      <template v-slot:titles>
        <h1>User Account</h1>
        <h2>
          Email address: <span>{{ currentUser.email }}</span>
        </h2>
      </template>
    </PageHeader>

    <section class="flex justify-between">
      <a @click="doUpdateStats()" class="font-medium text-blue-darker">Update my statistics</a>
      <a @click="doMigration()" class="font-medium text-blue-darker">Migration actions</a>
      <a @click="doLogout()" class="font-medium text-blue-darker">Log out</a>
    </section>

    <section class="flex flex-col space-y-8">
      <h2>Add movie by IMDb code</h2>
      <input class="no-icon" type="text" placeholder="IMDb movie code" v-model="imdbId" />
      <a @click="doAddMovie()" class="font-medium text-blue-darker">Add movie</a>
    </section>

    <section class="flex flex-col space-y-8">
      <h2>Change password</h2>
      <input class="no-icon" type="password" placeholder="Enter a new password" v-model="password" />
      <a @click="doChangePassword()" class="font-medium text-blue-darker">Change</a>
    </section>

    <p v-if="response">{{ response }}</p>
  </main>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PageHeader from "@/components/PageHeader";
import Relations from "@/globals";

export default {
  name: "MovieCreate",
  components: {
    PageHeader
  },
  data() {
    return {
      password: "",
      response: false,
      imdbId: ""
    };
  },
  computed: {
    ...mapState(Relations.Users, ["currentUser", "isLoggedIn"])
  },
  methods: {
    ...mapActions(Relations.Movies, ["addMovie"]),
    ...mapActions(Relations.Users, ["changePassword", "logout", "runMigration", "updateStats"]),
    doLogout() {
      this.logout();
      this.$router.push({ name: "home" });
    },
    async doChangePassword() {
      this.response = false;
      this.response = await this.changePassword(this.password);
    },
    async doMigration() {
      this.response = false;
      this.response = await this.runMigration();
    },
    async doUpdateStats() {
      this.response = false;
      this.response = await this.updateStats();
    },
    async doAddMovie() {
      this.response = false;

      const movie = await this.addMovie(this.imdbId);

      if (movie.seqId) {
        this.$router.push({ name: "single", params: { seqId: movie.seqId } });
      } else {
        this.response = "Movie already exists";
      }
    }
  }
};
</script>
