<template>
  <div class="vote-slider">
    <Badge :value="votes" />
    <range-slider min="0" max="5" step="0.5" v-model="votes" @change="getByVote" />
  </div>
</template>

<script>
import RangeSlider from "vue-range-slider";
import Badge from "@/components/Badge";
import Relations from "@/globals";

export default {
  name: "FilterVote",
  components: {
    Badge,
    RangeSlider
  },
  data() {
    return {
      votes: 0
    };
  },
  methods: {
    getByVote(votes) {
      if (votes !== 0) {
        this.$router.push({ name: Relations.Votes, params: { field: Relations.Votes, value: votes } });
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/utils";

.vote-slider .range-slider {
  width: 100%;
}

.vote-slider .range-slider-fill {
  background: $color-first;
}
</style>
