import { differenceInDays, format, formatDistanceToNow, isThisYear, parse } from "date-fns";

const dateFormat = "ddMMyyyy";

const stringToDate = (dateString, f = dateFormat) => {
  return parse(dateString, f, new Date());
};

const dateToString = (date, f = dateFormat) => {
  return format(date, f);
};

const timeAgo = date => {
  const diffInDays = differenceInDays(new Date(), new Date(date));
  const dateFormat = `d MMMM${isThisYear(new Date(date)) ? "" : ", yyyy"}`;
  return diffInDays > 31
    ? dateToString(new Date(date), dateFormat)
    : `${formatDistanceToNow(new Date(date), new Date())} ago`;
};

export { dateToString, stringToDate, timeAgo };
