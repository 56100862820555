<template>
  <div class="space-y-16">
    <header class="flex justify-between">
      <p v-if="results < total">
        <span class="hide--mobile">Showing</span> <span>{{ results }}</span> of
        <span>{{ total }} {{ getType(type, total) }}</span>
      </p>

      <p v-else class="flex space-x-4">
        <template v-if="total === 1">Showing all {{ getType(type, total) }}</template>
        <template v-else>
          <span class="hide--mobile">Showing all</span>
          <span>{{ total }} {{ getType(type, total) }}</span>
        </template>
      </p>

      <slot name="sort" />
    </header>

    <slot />

    <footer>
      <p v-if="loading">Loading ...</p>

      <button
        v-if="page < totalPages && !loading"
        @click="nextPage"
        class="font-medium text-11 text-blue-darker uppercase"
      >
        Load more
      </button>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    page: {
      required: true,
      type: Number
    },
    results: {
      required: true,
      type: Number
    },
    total: {
      required: true,
      type: Number
    },
    totalPages: {
      required: true,
      type: Number
    },
    type: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    getType(type, total) {
      let t = total === 1 ? type.slice(0, -1) : type;
      return t;
    },
    async nextPage() {
      this.loading = true;
      await this.$emit("paginate", this.page + 1);
      this.loading = false;
    }
  }
};
</script>
