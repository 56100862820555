<template>
  <div class="bg-grey-dark h-full w-full" />
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
div {
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(#fff, 0) 0, rgba(#fff, 0.25) 20%, rgba(#fff, 0.5) 60%, rgba(#fff, 0));
    animation: shimmer 1.5s infinite;
    content: "";
  }
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
</style>
