<template>
  <div class="space-y-8">
    <FindFromCollection :field="field" />

    <ul v-if="loading" class="space-y-8">
      <li v-for="(n, index) in 5" :key="index" class="block h-20 overflow-hidden rounded-10">
        <SkeletonLoader />
      </li>
    </ul>

    <ul v-if="!loading && topCollection.length" class="bg-grey p-24 rounded-16 space-y-12">
      <li v-for="collection in topCollection" :key="collection.seqId">
        <router-link
          class="flex font-medium min-h-20 justify-between hover:text-blue transition"
          :to="{ name: 'votes', params: { field, value: collection.seqId } }"
        >
          {{ collection.name }} <Badge :value="collection.count" />
        </router-link>
      </li>
    </ul>

    <router-link class="flex justify-between" :to="{ name: 'relations', params: { relation: field } }">
      <span class="font-medium text-12 text-blue-darker">View all {{ field }}</span>
      <ChevronRight />
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Badge from "@/components/Badge";
import ChevronRight from "@/components/icons/ChevronRight";
import FindFromCollection from "@/components/filters/FindFromCollection";
import Relations from "@/globals";
import SkeletonLoader from "@/components/SkeletonLoader";

export default {
  name: "FilterCollection",
  components: {
    Badge,
    ChevronRight,
    FindFromCollection,
    SkeletonLoader
  },
  props: {
    data: {
      required: true,
      type: String
    },
    field: {
      required: true,
      type: String
    },
    loading: {
      required: true,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters(Relations.Votes, ["findStats"]),
    topCollection() {
      return this.findStats(this.data);
    }
  }
};
</script>
