<template>
  <div class="relative">
    <vue-simple-suggest
      v-model="searchKeyword"
      display-attribute="name"
      :debounce="250"
      value-attribute="seqId"
      :max-suggestions="0"
      :min-length="3"
      :list="doSearch"
      :placeholder="`Find movie`"
      @select="onSelect"
    />
    <Search class="input-icon" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import VueSimpleSuggest from "vue-simple-suggest";
import Search from "@/components/icons/Search";
import Relations from "@/globals";

export default {
  name: "FilterSearch",
  components: {
    Search,
    VueSimpleSuggest
  },
  data() {
    return {
      list: [],
      searchKeyword: ""
    };
  },
  methods: {
    ...mapActions(Relations.Movies, ["findMoviesByKeyword"]),
    async doSearch() {
      const response = await this.findMoviesByKeyword(this.searchKeyword);
      this.list = response || [];
      return this.list;
    },
    onSelect({ seqId }) {
      this.$router.push({ name: "single", params: { seqId } });
    }
  }
};
</script>
