var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"space-y-8"},_vm._l((_vm.list),function(ref){
var date = ref.date;
var name = ref.name;
var poster = ref.poster;
var seqId = ref.seqId;
var vote = ref.vote;
var years = ref.years;
return _c('li',{key:seqId,staticClass:"bg-grey hover:bg-grey-dark rounded-16 transition"},[_c('router-link',{staticClass:"fade-in flex items-center justify-between min-h-64 px-16 py-12 hover:shadow-sm space-x-16 transition",attrs:{"to":{ name: 'single', params: { seqId: seqId } }}},[_c('div',{staticClass:"block h-60 w-40"},[_c('img',{staticClass:"h-60 object-cover rounded-6 w-40",attrs:{"src":poster,"title":name}})]),_c('section',{staticClass:"flex-1"},[_c('p',{staticClass:"font-medium"},[_vm._v(_vm._s(name)+" ("+_vm._s(years)+")")]),(date)?_c('p',{staticClass:"font-light text-12"},[_vm._v(_vm._s(_vm.formatDate(date)))]):_vm._e()]),_c('aside',[_c('Vote',{attrs:{"value":vote}})],1),_c('ChevronRight')],1)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }