import Api from "@/services/api.service";

export default {
  async addVote(_, { seqId, type, vote }) {
    const headers = { Authorization: `Bearer ${localStorage.MMD}` };
    const method = type === "add" ? "POST" : "PUT";
    const response = await Api.request({ method, url: `user/movie/${seqId}/vote/${vote}`, headers });
    return response.error || response.data;
  },
  async getRecentVoteList({ commit }) {
    const response = await Api.request({ method: "GET", url: "movies/votes/recent" });
    if (response.data) {
      commit("setRecentVoteList", response.data);
    }
    return response.error || response.data;
  },
  async getRelation(_, { field, value }) {
    const response = await Api.request({ method: "GET", url: `movies/relation/${field}/${encodeURIComponent(value)}` });
    return response.data || false;
  },
  async getStats({ commit }) {
    const response = await Api.request({ method: "GET", url: "movies/user/stats" });
    if (response.data) {
      commit("setStats", response.data);
    }
    return response.error || response.data;
  },
  async getVote({ commit }, seqId) {
    const response = await Api.request({ method: "GET", url: `movies/${seqId}/vote` });
    if (response.data) {
      commit("setVote", response.data);
    }
    return response.error || response.data;
  },
  async getVotes({ commit }, { field, page, value }) {
    const queryString = `${field}/${value}`;
    commit("setActiveQueryString", queryString);

    const response = await Api.request({ method: "GET", url: `movies/votes/${queryString}/page/${page}` });

    if (response.data) {
      commit("setQuery", { data: response.data, queryString });
    }
    return response.error || response.data;
  }
};
