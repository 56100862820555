<template>
  <div class="flex items-center space-x-8">
    <img src="/images/star.png" height="18" width="18" />
    <p class="font-bold">{{ value.toFixed(1) }}</p>
  </div>
</template>

<script>
export default {
  name: "Vote",
  props: {
    value: {
      required: true,
      type: Number
    }
  }
};
</script>
