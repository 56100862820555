export default {
  setActiveQueryString(state, data) {
    state.activeQueryString = data;
  },
  setRecentVoteList(state, data) {
    state.recentVoteList = data;
  },
  setStats(state, data) {
    state.hasRequestedStats = true;

    if (Object.keys(data).length) {
      Object.values(data).forEach((values, index) => {
        state.stats[Object.keys(data)[index]] = values;
      });
    }
  },
  setQuery(state, { data: { details, metadata, results }, queryString }) {
    const currentList = state.queries[queryString];
    const newResults = results;
    const query = {
      details,
      metadata: metadata[0],
      results: currentList ? [...currentList.results, ...newResults] : newResults
    };

    state.queries = {
      ...state.queries,
      [queryString]: query
    };
  },
  setVote(state, { movies, ...rest }) {
    state.singleVotesList.push({ ...movies, ...rest });
  }
};
