export default {
  setActiveQueryString(state, data) {
    state.activeQueryString = data;
  },
  setGenres(state, genres) {
    state.genres = genres;
  },
  setQuery(state, { data: { details, metadata, results }, queryString }) {
    const currentList = state.queries[queryString];
    const query = {
      details,
      metadata: metadata[0],
      results: currentList ? [...currentList.results, ...results] : results
    };

    state.queries = {
      ...state.queries,
      [queryString]: query
    };
  }
};
