<template>
  <ul class="space-y-8">
    <li
      v-for="{ date, name, poster, seqId, vote, years } in list"
      :key="seqId"
      class="bg-grey hover:bg-grey-dark rounded-16 transition"
    >
      <router-link
        class="fade-in flex items-center justify-between min-h-64 px-16 py-12 hover:shadow-sm space-x-16 transition"
        :to="{ name: 'single', params: { seqId: seqId } }"
      >
        <div class="block h-60 w-40">
          <img class="h-60 object-cover rounded-6 w-40" :src="poster" :title="name" />
        </div>

        <section class="flex-1">
          <p class="font-medium">{{ name }} ({{ years }})</p>
          <p v-if="date" class="font-light text-12">{{ formatDate(date) }}</p>
        </section>

        <aside>
          <Vote :value="vote" />
        </aside>

        <ChevronRight />
      </router-link>
    </li>
  </ul>
</template>

<script>
import ChevronRight from "@/components/icons/ChevronRight";
import Vote from "@/components/Vote";
import { timeAgo } from "@/services/date.service";

export default {
  name: "MovieList",
  components: {
    ChevronRight,
    Vote
  },
  props: {
    list: {
      required: true,
      type: Array
    }
  },
  methods: {
    formatDate: timeAgo
  }
};
</script>
