<template>
  <section class="space-y-16 tablet-up:space-y-32">
    <PageHeader>
      <template v-slot:titles>
        <h1>Zoeken</h1>
      </template>
    </PageHeader>

    <FilterSearch />

    <nav>
      <ul class="space-y-20 tablet-up:space-y-40">
        <li v-for="(item, index) in listItems" :key="index" class="space-y-8">
          <header class="bg-blue py-12 px-16 rounded-16 text-center">
            <p class="font-bold text-12 text-white uppercase">{{ item.label }}</p>
          </header>

          <section>
            <component :is="item.component" :field="item.field" :data="item.data" :loading="loading" />
          </section>
        </li>
      </ul>
    </nav>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import FilterCollection from "@/components/filters/FilterCollection";
import FilterDate from "@/components/filters/FilterDate";
import FilterSearch from "@/components/filters/FilterSearch";
import FilterVote from "@/components/filters/FilterVote";
import Logo from "@/components/icons/Logo";
import PageHeader from "@/components/PageHeader";
import Relations from "@/globals";

export default {
  name: "FilterMain",
  components: {
    FilterDate,
    FilterCollection,
    FilterSearch,
    FilterVote,
    Logo,
    PageHeader
  },
  data() {
    return {
      listItems: [
        { component: "FilterCollection", label: "Directors", field: Relations.Directors, data: "topVotedDirectors" },
        { component: "FilterCollection", label: "Countries", field: Relations.Countries, data: "topVotedCountries" },
        { component: "FilterCollection", label: "Genres", field: Relations.Genres, data: "topVotedGenres" },
        { component: "FilterCollection", label: "Years", field: Relations.Years, data: "topVotedYears" },
        { component: "FilterCollection", label: "Actors", field: Relations.Actors, data: "topVotedActors" },
        { component: "FilterDate", label: "Dates", field: Relations.Date },
        { component: "FilterVote", label: "Votes", field: Relations.Votes }
      ],
      loading: false
    };
  },
  computed: {
    ...mapState(Relations.Votes, ["hasRequestedStats"])
  },
  async mounted() {
    if (!this.hasRequestedStats) {
      this.loading = true;
      await this.getStats();
      this.loading = false;
    }
  },
  methods: {
    ...mapActions(Relations.Votes, ["getStats"])
  }
};
</script>
