import Api from "@/services/api.service";

export default {
  async getGenres({ commit }) {
    const response = await Api.request({ method: "GET", url: "movies/genres" });
    if (response.data) {
      commit("setGenres", response.data);
    }
    return response.error || response.data;
  },
  async getRelations({ commit }, { page, relation, sort }) {
    const queryString = `${relation}/sort/${sort || "count"}`;
    commit("setActiveQueryString", queryString);

    const response = await Api.request({ method: "GET", url: `movies/relations/${queryString}/page/${page}` });
    if (response.data) {
      commit("setQuery", { data: response.data, queryString });
    }
    return response.error || response.data;
  }
};
