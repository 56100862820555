<template>
  <span
    :class="[type === 'dark' ? 'border-black border-opacity-20' : 'border-white border-opacity-50', 'border-l h-12']"
  />
</template>

<script>
export default {
  name: "Separator",
  props: {
    type: {
      required: false,
      type: String
    }
  }
};
</script>
