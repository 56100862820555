<template>
  <span
    class="bg-white block font-bold leading-none min-w-32 p-4 rounded-6 text-12 text-center text-black transition"
  >
    {{ value }}
  </span>
</template>

<script>
export default {
  name: "Badge",
  props: {
    value: {
      required: true,
      type: [String, Number]
    }
  }
};
</script>
