<template>
  <main class="space-y-16 tablet-up:space-y-32">
    <PageHeader v-if="movie">
      <template v-slot:titles>
        <h1>{{ movie.name }} ({{ movie.years[0].name }})</h1>
      </template>
    </PageHeader>

    <p v-if="error">{{ error }}</p>

    <template v-if="movie">
      <section class="space-y-16 tablet-up:space-y-32">
        <div class="flex flex-col space-y-8">
          <p class="font-medium">Title</p>

          <div class="flex space-x-8">
            <input class="no-icon" type="text" v-model="movie.name" />
            <a @click="updateValue('name')" class="bg-orange flex items-center px-12 py-8 rounded-16 text-white">
              Change
            </a>
          </div>
        </div>

        <div class="flex flex-col space-y-8">
          <p class="font-medium">Runtime</p>

          <div class="flex space-x-8">
            <input class="no-icon" type="text" v-model="movie.runtime" />
            <a @click="updateValue('runtime')" class="bg-orange flex items-center px-12 py-8 rounded-16 text-white">
              Change
            </a>
          </div>
        </div>

        <div class="flex flex-col space-y-8">
          <p class="font-medium">Plot</p>
          <textarea class="no-icon" type="text" v-model="movie.plot" />
          <a @click="updateValue('plot')" class="bg-orange mr-auto px-12 py-8 rounded-16 text-white">Change</a>
        </div>

        <div v-for="(relation, index) in relationList" class="space-y-8" :key="index">
          <p class="capitalize font-medium">{{ relation }}</p>

          <ul class="space-y-8">
            <li
              v-for="{ name, seqId } in movie[relation]"
              :key="seqId"
              class="bg-grey flex items-center justify-between px-16 py-12 rounded-16"
            >
              <span>{{ name }}</span>
              <a @click="removeRelation({ relation, seqId })" class="bg-orange px-12 py-8 rounded-16 text-white">
                Remove
              </a>
            </li>
          </ul>
        </div>

        <!-- <div class="space-y-8">
          <p>Add genres</p>

          <ul v-if="filteredGenres.length" class="space-y-8">
            <li v-for="genre in filteredGenres" :key="genre.seqId" class="flex justify-between">
              <span class="font-bold">{{ genre.name }}</span>
              <a @click="addRelation({ seqId: genre.seqId, relation: 'genres' })">Add</a>
            </li>
          </ul>
        </div> -->
      </section>
    </template>
  </main>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import PageHeader from "@/components/PageHeader";
import Relations from "@/globals";

export default {
  name: "MovieEdit",
  components: {
    PageHeader
  },
  data() {
    return {
      error: false,
      movie: null,
      relationList: [Relations.Directors, Relations.Countries, Relations.Genres, Relations.Actors]
    };
  },
  async mounted() {
    if (this.$route.params.seqId) {
      await this.setMovieData(Number(this.$route.params.seqId));
    }
    if (!this.genres.length) {
      this.getGenres();
    }
  },
  computed: {
    ...mapGetters(Relations.Movies, ["findSingleMovie"]),
    ...mapState("relations", ["genres"]),
    filteredGenres() {
      return this.genres.filter(genre => !this.movie.genres.find(g => g.name === genre.name));
    }
  },
  methods: {
    ...mapActions(Relations.Movies, [
      "addRelationToMovie",
      "getMovie",
      "removeRelationFromMovie",
      "updateMovieByProperty"
    ]),
    ...mapActions("relations", ["getGenres"]),
    async setMovieData(seqId) {
      const findMovie = this.findSingleMovie(seqId);
      const response = findMovie || (await this.getMovie(seqId));

      if (!response.seqId) {
        this.error = response;
      } else {
        this.movie = response;
      }
    },
    async addRelation({ relation, seqId }) {
      const response = await this.addRelationToMovie({ movieSeqId: this.movie.seqId, relation, seqId });

      if (response) {
        this.movie = response;
      }
    },
    async removeRelation({ relation, seqId }) {
      const response = await this.removeRelationFromMovie({ movieSeqId: this.movie.seqId, relation, seqId });

      if (response) {
        this.movie = response;
      }
    },
    async updateValue(key) {
      await this.updateMovieByProperty({ seqId: this.movie.seqId, key, value: this.movie[key] });
    }
  }
};
</script>
