export default {
  Actors: "actors",
  Countries: "countries",
  Directors: "directors",
  Date: "date",
  Genres: "genres",
  Movies: "movies",
  Users: "users",
  Votes: "votes",
  Years: "years"
};
