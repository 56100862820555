import Relations from "@/globals";

const getImage = field => {
  switch (field) {
    case Relations.Actors:
      return "dog.png";
    case Relations.Countries:
      return "pinguin.png";
    case Relations.Directors:
      return "fox.png";
    case Relations.Date:
      return "owl.png";
    case Relations.Genres:
      return "horse.png";
    case Relations.Years:
      return "pig.png";
    case Relations.Votes:
      return "owl.png";
    default:
      return "owl.png";
  }
}

const getTitle = field => {
  switch (field) {
    case Relations.Actors:
      return "actor";
    case Relations.Countries:
      return "country";
    case Relations.Directors:
      return "director";
    case Relations.Date:
      return "date range";
    case Relations.Genres:
      return "genre";
    case Relations.Years:
      return "year";
    case Relations.Votes:
      return "vote";
    default:
      return "nothing found";
  }
};

export { getImage, getTitle };
