<template>
  <div class="relative">
    <v-date-picker
      class="date-picker"
      :input-props="{ placeholder: 'Pick a date range' }"
      mode="range"
      v-model="date"
      :popover="{ visibility: 'click' }"
      :max-date="new Date()"
      @popoverWillHide="getByDates"
    />
    <Calendar class="input-icon" />
  </div>
</template>

<script>
import Calendar from "@/components/icons/Calendar";
import { dateToString } from "@/services/date.service";
import Relations from "@/globals";

export default {
  name: "FilterDate",
  components: {
    Calendar
  },
  data() {
    return {
      date: {}
    };
  },
  methods: {
    getByDates() {
      if (this.date.start && this.date.end) {
        const date = [dateToString(this.date.end), dateToString(this.date.start)].join();
        this.$router.push({ name: Relations.Votes, params: { field: Relations.Date, value: date } });
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/utils";
.filter-date {
  margin-left: auto;
}
.date-picker {
  .vc-text-lg {
    font-size: 1.4rem;
  }

  .vc-text-sm {
    font-size: 1.4rem;
  }

  .vc-text-gray-400 {
    color: lighten($grey, 20%);
  }

  .vc-text-gray-500 {
    color: $grey;
  }

  .vc-text-gray-900,
  .vc-text-blue-900 {
    color: $black;
  }

  .vc-bg-blue-200 {
    background: $color-first;
  }

  .vc-bg-blue-600 {
    background: $color-first;
  }

  .vc-day-content:hover {
    background-color: darken($color-first, 5%) !important;
    color: #fff;
  }
}
</style>
