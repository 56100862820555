<template>
  <main class="space-y-16">
    <PageHeader>
      <template v-slot:titles>
        <h1>Login</h1>
      </template>
    </PageHeader>

    <section class="section">
      <form @submit.prevent="submit" class="space-y-8">
        <input class="no-icon" type="text" placeholder="Email address" v-model="email" />

        <input class="no-icon" type="password" placeholder="Password" v-model="password" />

        <button
          class="bg-blue hover:bg-blue-dark font-medium p-16 rounded-16 text-white transition w-full"
          type="submit"
        >
          Login
        </button>
      </form>

      <p v-if="message">{{ message }}</p>
    </section>
  </main>
</template>

<script>
import { mapActions } from "vuex";
import PageHeader from "@/components/PageHeader";
import Relations from "@/globals";

export default {
  name: "UserRegister",
  components: {
    PageHeader
  },
  data() {
    return {
      email: "",
      message: false,
      password: ""
    };
  },
  methods: {
    ...mapActions(Relations.Users, ["loginUser"]),
    async submit() {
      const response = await this.loginUser({ email: this.email, password: this.password });
      if (response.user) {
        this.$router.push("/");
      } else {
        this.message = response;
      }
    }
  }
};
</script>
