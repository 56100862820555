export default {
  setMovieDetailsList(state, data) {
    state.movieDetailsList.push(data);
  },
  updateMovieDetailList(state, data) {
    const findIndex = state.movieDetailsList.findIndex(movie => movie.seqId === data.seqId);

    if (findIndex > -1) {
      state.movieDetailsList[findIndex] = data;
    }
  }
};
