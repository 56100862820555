<template>
  <div class="relative">
    <div v-if="stats.totalVotes" class="bg-black flex items-center p-16 rounded-16 space-x-16">
      <div>
        <img src="/images/pinguin.png" height="40" width="40" />
      </div>

      <div>
        <p class="text-white space-x-4">
          <span>Total votes:</span> <span class="font-bold">{{ stats.totalVotes }}</span>
        </p>
        <p class="text-white space-x-4"><span>Voting since:</span> <span class="font-bold">28 September, 2006</span></p>
      </div>
    </div>

    <div v-else class="block h-72 overflow-hidden rounded-16">
      <SkeletonLoader />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import Relations from "@/globals";
import SkeletonLoader from "@/components/SkeletonLoader";

export default {
  name: "Intro",
  components: {
    SkeletonLoader
  },
  computed: {
    ...mapState(Relations.Votes, ["stats"])
  }
};
</script>
