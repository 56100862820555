import axios from "axios";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 60000,
  withCredentials: false
});

const request = async ({ method, url, data = false, headers = false }) => {
  try {
    const config = { method, url };
    if (data) {
      config.data = data;
    }
    if (headers) {
      config.headers = headers;
    }

    const response = await api(config);
    return response.data;
  } catch ({ response }) {
    if (response.status === 401) {
      response.data = { data: { error: "Unauthorized" } };
    }
    return response.data;
  }
};

export default { request };
