<template>
  <header class="flex justify-between">
    <div class="space-y-4">
      <slot name="titles" />
    </div>

    <slot name="aside" />
  </header>
</template>

<script>
export default {
  name: "PageHeader"
};
</script>
