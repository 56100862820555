<template>
  <div class="space-y-12">
    <header class="flex justify-center">
      <Header>Top votes</Header>
    </header>

    <section>
      <ul v-if="loading" class="space-y-8">
        <li v-for="(n, index) in 5" :key="index" class="block h-64 overflow-hidden rounded-16">
          <SkeletonLoader />
        </li>
      </ul>

      <ul v-if="!loading && hasRequestedStats" class="space-y-8">
        <template v-for="(relation, index) in relations">
          <li
            class="bg-grey hover:bg-grey-dark rounded-16 transition"
            :key="index"
            v-if="findStats(relation.name).length"
          >
            <router-link
              class="
                fade-in
                flex
                items-center
                justify-between
                min-h-64
                px-16
                py-12
                space-x-16
                transition
              "
              :to="{
                name: 'votes',
                params: {
                  field: relation.field,
                  value: getRelation(relation.name).seqId || getRelation(relation.name).name
                }
              }"
            >
              <img
                class="h-32 object-cover rounded-full"
                :src="`images/${relationImage(relation.field)}`"
                :title="getRelation(relation.name).name"
              />

              <section class="flex-1 flex-col space-y-4">
                <p class="font-medium">{{ getRelation(relation.name).name }}</p>
                <p class="font-light text-12">Most voted {{ relation.label }}</p>
              </section>

              <aside class="flex space-x-16">
                <Badge :value="getRelation(relation.name).count" />
                <Vote :show-bar="false" :value="getRelation(relation.name).avgVote" />
              </aside>

              <ChevronRight />
            </router-link>
          </li>
        </template>
      </ul>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import { getImage } from "@/services/vote.service";
import Badge from "@/components/Badge";
import ChevronRight from "@/components/icons/ChevronRight";
import Header from "@/components/Header";
import Vote from "@/components/Vote";
import Relations from "@/globals";
import SkeletonLoader from "@/components/SkeletonLoader";

export default {
  name: "TopVotes",
  components: {
    Badge,
    ChevronRight,
    Header,
    SkeletonLoader,
    Vote
  },
  data() {
    return {
      loading: false,
      relations: [
        { name: "topVotedDirectors", field: Relations.Directors, label: "director" },
        { name: "topVotedCountries", field: Relations.Countries, label: "country" },
        { name: "topVotedGenres", field: Relations.Genres, label: "genre" },
        { name: "topVotedYears", field: Relations.Years, label: "year" },
        { name: "topVotedActors", field: Relations.Actors, label: "actor" }
      ]
    };
  },
  computed: {
    ...mapState(Relations.Votes, ["hasRequestedStats"]),
    ...mapGetters(Relations.Votes, ["findStats"])
  },
  async mounted() {
    if (!this.hasRequestedStats) {
      this.loading = true;
      await this.getStats();
      this.loading = false;
    }
  },
  methods: {
    ...mapActions(Relations.Votes, ["getStats"]),
    relationImage(field) {
      return getImage(field);
    },
    getRelation(name) {
      return this.findStats(name)[0];
    }
  }
};
</script>
