<template>
  <div class="space-y-12">
    <header class="flex justify-center">
      <Header>Recent votes</Header>
    </header>

    <section>
      <ul v-if="loading" class="space-y-8">
        <li v-for="(n, index) in 10" :key="index" class="block h-84 overflow-hidden rounded-16">
          <SkeletonLoader />
        </li>
      </ul>

      <ul v-if="!loading && recentVoteList.length" class="space-y-8">
        <li
          v-for="({ date, name, poster, seqId, vote, years }, index) in recentVoteList"
          :key="index"
          class="bg-grey hover:bg-grey-dark rounded-16"
        >
          <router-link
            class="fade-in flex items-center justify-between px-16 py-12 space-x-16 transition"
            :to="{ name: 'single', params: { seqId: seqId } }"
          >
            <div class="block h-60 w-40">
              <img class="h-60 object-cover rounded-6 w-40" :src="poster" :title="name" />
            </div>

            <section class="flex-1 truncate space-y-4">
              <p class="font-medium truncate">{{ name }} ({{ years }})</p>
              <p class="font-light text-12">Voted {{ dateFormat(date) }}</p>
            </section>

            <aside>
              <Vote :show-bar="false" :value="vote" />
            </aside>

            <ChevronRight />
          </router-link>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { dateToString } from "@/services/date.service";
import ChevronRight from "@/components/icons/ChevronRight";
import Header from "@/components/Header";
import Vote from "@/components/Vote";
import Relations from "@/globals";
import SkeletonLoader from "@/components/SkeletonLoader";

export default {
  name: "TopVotes",
  components: {
    ChevronRight,
    Header,
    Vote,
    SkeletonLoader
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapState(Relations.Votes, ["recentVoteList"])
  },
  async mounted() {
    if (!this.recentVoteList.length) {
      this.loading = true;
      await this.getRecentVoteList();
      this.loading = false;
    }
  },
  methods: {
    ...mapActions(Relations.Votes, ["getRecentVoteList"]),
    dateFormat(date) {
      return dateToString(new Date(date), "d MMMM, yyyy");
    },
    getRelation(name) {
      return this.stats(name)[0];
    }
  }
};
</script>
