<template>
  <main>
    <FilterMain />
  </main>
</template>

<script>
import FilterMain from "@/components/filters/FilterMain";

export default {
  name: "Filters",
  components: {
    FilterMain
  },
  metaInfo: {
    title: "Jille Borg",
    titleTemplate: `%s | Movie Filters`
  }
};
</script>
