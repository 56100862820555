<template>
  <div class="relative">
    <vue-simple-suggest
      v-model="searchKeyword"
      display-attribute="name"
      :debounce="250"
      value-attribute="seqId"
      :max-suggestions="0"
      :min-length="minLength"
      :list="onFocus"
      :filter-by-query="true"
      :placeholder="`Find ${field}`"
      @select="onSelect"
    />
    <Search class="input-icon" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import VueSimpleSuggest from "vue-simple-suggest";
import Search from "@/components/icons/Search";
import Relations from "@/globals";

export default {
  name: "FindFromCollection",
  components: {
    Search,
    VueSimpleSuggest
  },
  props: {
    field: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      hasRequested: false,
      list: [],
      searchKeyword: ""
    };
  },
  watch: {
    list(list) {
      if (list.length === 1) {
        this.navigate({ seqId: list[0].seqId });
      }
    }
  },
  computed: {
    minLength() {
      return this.field === Relations.Genres ? 0 : 3;
    }
  },
  methods: {
    ...mapActions(Relations.Votes, ["getRelation"]),
    navigate({ seqId }) {
      this.$router.push({ name: Relations.Votes, params: { field: this.field, value: seqId } });
    },
    async onFocus() {
      if (!this.hasRequested || this.field !== Relations.Genres) {
        const response = await this.getRelation({ field: this.field, value: this.searchKeyword });
        this.hasRequested = true;
        this.list = response || [];
        return this.list;
      } else if (this.field === Relations.Genres && this.list.length) {
        return this.list;
      }
    },
    onSelect({ seqId }) {
      this.navigate({ seqId });
    }
  }
};
</script>
