<template>
  <main class="space-y-16 tablet-up:space-y-32">
    <Intro />
    <RecentVotes />
    <TopVotes />
  </main>
</template>

<script>
import Intro from "@/components/Intro";
import RecentVotes from "@/components/RecentVotes";
import TopVotes from "@/components/TopVotes";

export default {
  name: "Home",
  components: {
    Intro,
    RecentVotes,
    TopVotes
  },
  metaInfo: {
    title: "Jille Borg",
    titleTemplate: `%s | Movie Votes`
  }
};
</script>
