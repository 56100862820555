<template>
  <main class="space-y-16 tablet-up:space-y-32">
    <p v-if="error">{{ error }}</p>
    <p v-if="loadingMovie">Loading...</p>

    <div v-if="!loadingMovie && movie" class="space-y-16 tablet-up:space-y-32">
      <div class="space-y-12">
        <header class="bg-blue flex items-center p-16 rounded-16 space-x-16">
          <img
            v-if="movie.poster"
            :src="formatPosterUrl(movie.poster)"
            class="h-90 tablet-up:h-120 object-cover rounded-16 w-60 tablet-up:w-80"
            loading="lazy"
          />

          <div>
            <h1 class="font-bold tablet-up:leading-10 text-16 tablet-up:text-20 text-white">
              {{ movie.name }} ({{ year }})
            </h1>

            <p class="flex items-center mb-12 opacity-50 space-x-8">
              <span class="text-white">{{ movie.runtime }}</span>

              <Separator type="light" />

              <a
                :href="`https://www.imdb.com/title/${movie.imdbId}/`"
                class="text-white hover:underline"
                target="_blank"
              >
                IMDb
              </a>

              <template v-if="isLoggedIn">
                <Separator type="light" />

                <router-link :to="{ name: 'edit' }" class="text-white hover:underline">Edit movie</router-link>
              </template>
            </p>

            <p class="line-clamp-2 tablet-up:line-clamp-3 text-white">{{ movie.plot }}</p>
          </div>
        </header>

        <div v-if="vote" class="bg-grey flex justify-between px-16 py-12 rounded-16">
          <p><span class="font-bold">Voted</span> {{ dateFormat(vote.date) }}</p>

          <Vote :value="vote.vote" />
        </div>

        <div
          v-if="!loadingVote && isLoggedIn"
          class="bg-grey flex items-center px-16 py-12 rounded-16 space-x-16 vote-slider"
        >
          <range-slider min="0" max="5" step="0.5" v-model="voteValue" />

          <Badge :value="voteValue" />

          <a @click="doAddVote" class="bg-orange px-12 py-8 rounded-16 text-white whitespace-pre">
            <span v-if="vote">Update</span><span v-else>Add</span> vote
          </a>
        </div>
      </div>

      <section class="space-y-16 tablet-up:space-y-32">
        <template v-for="(relation, index) in relationList">
          <section v-if="movie[relation].length" :key="index" class="space-y-8">
            <p class="capitalize font-medium">{{ relation }}</p>

            <RelationList :list="movie[relation]" :relation="relation" />
          </section>
        </template>
      </section>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import RangeSlider from "vue-range-slider";

import { dateToString } from "@/services/date.service";
import Badge from "@/components/Badge";
import RelationList from "@/components/RelationList";
import Relations from "@/globals";
import Separator from "@/components/Separator";
import Vote from "@/components/Vote";

export default {
  name: "Movie",
  components: {
    Badge,
    RelationList,
    RangeSlider,
    Separator,
    Vote
  },
  metaInfo() {
    return {
      title: "Jille Borg",
      titleTemplate: `%s | ${this.movie ? this.movie.name : "Movie Votes"}`,
      meta: [
        { property: "og:image", content: this.movie ? this.movie.poster : "" },
        { property: "og:type", content: "article" },
        { property: "og:title", content: `Jille Borg | ${this.movie ? this.movie.name : "Movie Votes"}` },
        { property: "og:description", content: this.movie ? this.movie.plot : "" }
      ]
    };
  },
  data() {
    return {
      error: false,
      loadingMovie: false,
      loadingVote: true,
      movie: null,
      relationList: [Relations.Years, Relations.Directors, Relations.Countries, Relations.Genres, Relations.Actors],
      vote: false,
      voteValue: 0
    };
  },
  async mounted() {
    if (this.$route.params.seqId) {
      await this.setMovieData(Number(this.$route.params.seqId));
    }
  },
  computed: {
    ...mapGetters(Relations.Movies, ["findSingleMovie"]),
    ...mapGetters(Relations.Votes, ["findSingleVote"]),
    ...mapState(Relations.Votes, ["recentVoteList"]),
    ...mapState(Relations.Users, ["isLoggedIn"]),
    year() {
      return this.movie && this.movie.years.length ? this.movie.years[0].name : "";
    }
  },
  methods: {
    ...mapActions(Relations.Movies, ["getMovie"]),
    ...mapActions(Relations.Votes, ["addVote", "getVote"]),
    dateFormat(date) {
      return dateToString(new Date(date), "d MMMM, yyyy");
    },
    formatPosterUrl(url) {
      return url.replace("s3.eu-central-1.amazonaws.com/", "");
    },
    async doAddVote() {
      if (this.voteValue > 0) {
        const type = this.vote ? "update" : "add";
        await this.addVote({ seqId: Number(this.$route.params.seqId), type, vote: this.voteValue });
        this.vote = { date: new Date(), vote: this.voteValue };
      }
    },
    async setMovieData(seqId) {
      this.loadingMovie = true;

      const findMovie = this.findSingleMovie(seqId);
      const response = findMovie || (await this.getMovie(seqId));

      if (!response.seqId) {
        this.error = response;
        this.loadingMovie = false;
      } else {
        this.movie = response;
        this.loadingMovie = false;

        await this.loadVote(seqId);
      }
    },
    async loadVote(seqId) {
      const findVote = this.findSingleVote(seqId);
      const vote = findVote || (await this.getVote(seqId));

      if (vote && vote.vote) {
        this.vote = vote;
        this.voteValue = vote.vote;
      }

      this.loadingVote = false;
    }
  }
};
</script>
